import * as React from "react"
import type { HeadFC } from "gatsby"
import "../styles/index.css"


const IndexPage = () => {
  return (
    <main id="main">
      <div id="svgContainer">
        <div id="svgHeader"></div>
      </div>
      <h1 id="header">
        Under Construction
      </h1>
    </main>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>Fab Joinery</title>
